"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("./lib");
const lib_2 = require("./lib");
const lib_3 = require("./lib");
const lib_4 = require("./lib");
const lib_5 = require("./lib");
const lib_6 = require("./lib");
const lib_7 = require("./lib");
const lib_8 = require("./lib");
require("../scss/style.scss");
document.addEventListener('DOMContentLoaded', () => {
    (0, lib_1.setDvh)();
    (0, lib_2.setSvh)();
    (0, lib_7.setCurrentSection)();
    (0, lib_5.humbergerMenuUtils)();
    (0, lib_6.smoothScroll)();
});
window.addEventListener('load', () => {
    (0, lib_3.ISOvserve)();
    (0, lib_4.ISOvserveRepeat)();
    (0, lib_7.setCurrentSection)();
    (0, lib_8.loaded)();
});
window.addEventListener('resize', () => {
    (0, lib_1.setDvh)();
});
