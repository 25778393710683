"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loaded = exports.removeClass = exports.addClass = exports.hasClass = exports.qsAll = exports.qs = exports.isActive = exports.getPathHash = exports.getHash = exports.getPath = exports.getParam = exports.getHeaderHeight = exports.scrollToOffset = exports.toggleClass = exports.setSvh = exports.setDvh = exports.smoothScroll = exports.humbergerMenuUtils = exports.setCurrentSection = exports.ISOvserveRepeat = exports.ISOvserve = void 0;
/*=======================================
  IntersectionObserver系
=======================================*/
// IntersectionObserver共通関数
function ISOvserver(targetSelectors, options, ifFunc, elseFunc = () => { }) {
    const targets = qsAll(targetSelectors.join(", "));
    if (targets) {
        let observer = new IntersectionObserver(callback(ifFunc, elseFunc), options);
        for (const elm of targets) {
            observer.observe(elm);
        }
    }
    function callback(ifFunc, elseFunc) {
        return function (entries) {
            for (const elm of entries) {
                if (elm.isIntersecting) {
                    ifFunc(elm);
                }
                else {
                    elseFunc(elm);
                }
            }
        };
    }
}
function ISOvserve() {
    const targetSelectors = [".c__js_fade", ".c__js_blur", ".js__transition", ".js__animation", ".js__observe", ".c__js_highlights", ".c__js_fade_delay", ".c__js_blur_delay"];
    const options = {
        rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
    };
    function ifFunc(elm) {
        elm.target.classList.add("active");
    }
    ISOvserver(targetSelectors, options, ifFunc);
}
exports.ISOvserve = ISOvserve;
function ISOvserveRepeat() {
    const targetSelectors = [".js__observe_repeat"];
    const options = {
        rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
    };
    function ifFunc(elm) {
        elm.target.classList.add("active");
    }
    function elseFunc(elm) {
        elm.target.classList.remove("active");
    }
    ISOvserver(targetSelectors, options, ifFunc, elseFunc);
}
exports.ISOvserveRepeat = ISOvserveRepeat;
function setCurrentSection() {
    const targetSelectors = ["#concept", "#feature", "#recruit", "#company"];
    const options = {
        rootMargin: "-10% 0% -85% 0%", // 画面のかなり上のほうまで来たら
    };
    function ifFunc(elm) {
        addClass(qs("#js__menu_" + elm.target.id), "active");
    }
    function elseFunc(elm) {
        removeClass(qs("#js__menu_" + elm.target.id), "active");
    }
    ISOvserver(targetSelectors, options, ifFunc, elseFunc);
}
exports.setCurrentSection = setCurrentSection;
/*=======================================
  ハンバーガーメニューまわり
=======================================*/
function humbergerMenuUtils() {
    const toggle = qs("#header_toggle");
    const spMenu = qs("#sp_menu");
    const overlay = qs("#overlay");
    toggle.addEventListener("click", () => {
        toggleMenu();
    });
    overlay.addEventListener("click", () => {
        toggleMenu();
    });
    const spMenuLinks = qsAll("a", spMenu);
    for (const elm of spMenuLinks) {
        elm.addEventListener("click", () => {
            toggleMenu();
        });
    }
}
exports.humbergerMenuUtils = humbergerMenuUtils;
function toggleMenu() {
    const toggle = qs("#header_toggle");
    const spMenu = qs("#sp_menu");
    const overlay = qs("#overlay");
    toggleClass(toggle, "active");
    toggleClass(spMenu, "active");
    toggleClass(overlay, "active");
    if (hasClass(toggle, "active")) {
        preventScroll();
    }
    else {
        arrowScroll();
    }
}
function preventScroll() {
    document.addEventListener("wheel", preventDef, { passive: false });
    document.addEventListener("touchmove", preventDef, { passive: false });
}
function arrowScroll() {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchmove", preventDef);
}
// スクロール禁止関数
function preventDef(e) {
    e.preventDefault();
}
/*=======================================
  スムーススクロール系
=======================================*/
function smoothScroll() {
    const scrollAnchor = qsAll('a[href^="#"]');
    const scrollAnchorArr = Array.prototype.slice.call(scrollAnchor);
    let targetId;
    let targetOffsetTop;
    // ページ内でのアンカーの場合
    for (const link of scrollAnchorArr) {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            targetId = link.hash;
            if (targetId) {
                let targetElement = qs(targetId);
                targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
            }
            else {
                targetOffsetTop = 0;
            }
            window.scrollTo({
                top: targetOffsetTop,
                behavior: "smooth"
            });
        });
    }
    // ページ外からのアンカーの場合
    const anchorId = location.hash;
    if (anchorId.indexOf("#") != -1) {
        let target = qs(anchorId);
        let position = window.pageYOffset + target.getBoundingClientRect().top;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        });
    }
    // ページトップボタンとロゴの表示・非表示
    const top = qs('.js__first_view');
    if (top) {
        const pagetopButton = qs('#pagetop_button');
        const headerLogo = qs('#js__header_logo');
        const options = {
            rootMargin: "170px 0px 0px 0px",
        };
        const observer = new IntersectionObserver((entries) => {
            for (const e of entries) {
                if (e.isIntersecting) {
                    pagetopButton.classList.remove('active');
                    headerLogo.classList.remove('active');
                }
                else {
                    pagetopButton.classList.add('active');
                    headerLogo.classList.add('active');
                }
            }
        }, options);
        observer.observe(top);
        pagetopButton.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        });
        headerLogo.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        });
    }
}
exports.smoothScroll = smoothScroll;
/*=======================================
  その他関数
=======================================*/
function setDvh() {
    setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--dvh', `${vh}px`);
    }, 500);
}
exports.setDvh = setDvh;
function setSvh() {
    setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--svh', `${vh}px`);
    }, 500);
}
exports.setSvh = setSvh;
function toggleClass(element, className) {
    if (element.className.includes(className)) {
        element.classList.remove(className);
    }
    else {
        element.classList.add(className);
    }
}
exports.toggleClass = toggleClass;
function scrollToOffset(offset) {
    window.scrollTo({
        top: offset,
        behavior: "smooth"
    });
}
exports.scrollToOffset = scrollToOffset;
function getHeaderHeight() {
    const pc_header_selector = "#header .header_l";
    const sp_header_selector = "#sp_menu .sp_menu_logo";
    const pcHeaderHeight = qs(pc_header_selector).offsetHeight;
    const tabHeaderHeight = qs(sp_header_selector).offsetHeight;
    return pcHeaderHeight + tabHeaderHeight + 40;
}
exports.getHeaderHeight = getHeaderHeight;
function getParam(param) {
    let url = new URL(window.location.href);
    let value = url.searchParams.get(param);
    return value ? value : "";
}
exports.getParam = getParam;
function getPath() {
    return window.location.pathname;
}
exports.getPath = getPath;
function getHash() {
    return window.location.hash;
}
exports.getHash = getHash;
function getPathHash() {
    return window.location.pathname + window.location.hash;
}
exports.getPathHash = getPathHash;
function isActive(elm) {
    return elm === null || elm === void 0 ? void 0 : elm.className.includes("active");
}
exports.isActive = isActive;
function qs(name, elm = document) {
    return elm === null || elm === void 0 ? void 0 : elm.querySelector(name);
}
exports.qs = qs;
function qsAll(name, elm = document) {
    return elm === null || elm === void 0 ? void 0 : elm.querySelectorAll(name);
}
exports.qsAll = qsAll;
function hasClass(elm, name) {
    return elm === null || elm === void 0 ? void 0 : elm.className.includes(name);
}
exports.hasClass = hasClass;
function addClass(elm, name) {
    if (elm)
        elm.classList.add(name);
}
exports.addClass = addClass;
function removeClass(elm, name) {
    if (elm && hasClass(elm, name)) {
        elm.classList.remove(name);
    }
}
exports.removeClass = removeClass;
function loaded() {
    let loader = qsAll(".js__load_required");
    for (const elm of loader) {
        addClass(elm, "loaded");
    }
}
exports.loaded = loaded;
